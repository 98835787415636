// Enabling a global utillity to fix heights
// in areas of the app where we need them to expand fully vertically.
// Due Lightspeed iframe and new toolbar, we need to be more specific. Oh boy.

.app-lightspeed-height-adjustment {

    .app-body {
        padding-bottom: 0;
    }

    .CurrentProjectState {
        height: calc(100vh - 20px);
    }
    
    .CurrentProjectState .RouteHomeInbox {
        height: 100vh;
    }
    
    .CurrentProjectState .b-scheduler-container.is-projects-scheduler > .b-widget,
    .CurrentProjectState .b-scheduler-container.is-people-scheduler > .b-widget {
        height: calc(100vh - 130px) !important;
        max-height: none !important;
    }

    #DashboardContent .boards {
        height: calc(100vh - 120px) !important;
        padding-bottom: 10px;
    }

}
